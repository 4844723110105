define("@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Compositions::CompositionCarouselSwipe
  	class={{if @transparent "bg_transparent"}}
  	@content={{@bubbles}}
  	@accessibilityText={{t "label.personalizedProducts"}}
  	@controls={{component
  		"cards/card-control"
  		accessibilityTextPrevious=(t "label.accesibility.previous")
  		accessibilityTextNext=(t "label.accesibility.next")
  	}}
  	@selected={{if (gte this.selected @bubbles.length) 0 this.selected}}
  	@onChange={{fn (mut this.selected)}}
  	as |bubble slide|
  >
  	{{#if @productId}}
  		{{#if (eq @productId bubble.productId)}}
  			<CarouselFuturaBubblesCard
  				@bubble={{bubble}}
  				@slide={{slide}}
  				@currentSession={{@currentSession}}
  				@transitionTo={{@transitionTo}}
  				@openModal={{@openModal}}
  				@onClose={{@onClose}}
  			/>
  		{{/if}}
  	{{else}}
  		<CarouselFuturaBubblesCard
  			@bubble={{bubble}}
  			@slide={{slide}}
  			@currentSession={{@currentSession}}
  			@transitionTo={{@transitionTo}}
  			@openModal={{@openModal}}
  			@onClose={{@onClose}}
  		/>
  	{{/if}}
  </Compositions::CompositionCarouselSwipe>
  */
  {
    "id": "L61MznED",
    "block": "[[[8,[39,0],[[16,0,[52,[30,1],\"bg_transparent\"]]],[[\"@content\",\"@accessibilityText\",\"@controls\",\"@selected\",\"@onChange\"],[[30,2],[28,[37,2],[\"label.personalizedProducts\"],null],[50,\"cards/card-control\",0,null,[[\"accessibilityTextPrevious\",\"accessibilityTextNext\"],[[28,[37,2],[\"label.accesibility.previous\"],null],[28,[37,2],[\"label.accesibility.next\"],null]]]],[52,[28,[37,4],[[30,0,[\"selected\"]],[30,2,[\"length\"]]],null],0,[30,0,[\"selected\"]]],[28,[37,5],[[28,[37,6],[[30,0,[\"selected\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[41,[28,[37,7],[[30,5],[30,3,[\"productId\"]]],null],[[[1,\"\\t\\t\\t\"],[8,[39,8],null,[[\"@bubble\",\"@slide\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],[[30,3],[30,4],[30,6],[30,7],[30,8],[30,9]]],null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"\\t\\t\"],[8,[39,8],null,[[\"@bubble\",\"@slide\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],[[30,3],[30,4],[30,6],[30,7],[30,8],[30,9]]],null],[1,\"\\n\"]],[]]]],[3,4]]]]]],[\"@transparent\",\"@bubbles\",\"bubble\",\"slide\",\"@productId\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],false,[\"compositions/composition-carousel-swipe\",\"if\",\"t\",\"component\",\"gte\",\"fn\",\"mut\",\"eq\",\"carousel-futura-bubbles-card\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles.hbs",
    "isStrictMode": false
  });
  let CarouselFuturaBubblesComponent = _exports.default = (_class = class CarouselFuturaBubblesComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "select", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "select", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselFuturaBubblesComponent);
});