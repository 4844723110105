define("ember-alerter/templates/components/alert-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentAlert}}
  	<AlertItem @model={{this.currentAlert}}>
  		{{yield this.currentAlert}}
  	</AlertItem>
  {{/if}}
  */
  {
    "id": "khw42e/a",
    "block": "[[[41,[30,0,[\"currentAlert\"]],[[[1,\"\\t\"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"currentAlert\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[18,1,[[30,0,[\"currentAlert\"]]]],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"alert-item\",\"yield\"]]",
    "moduleName": "ember-alerter/templates/components/alert-container.hbs",
    "isStrictMode": false
  });
});